<template>
  <div class="staff">
    <img src="@assets/images/backStaff.jpg" />
    <div class="container">
      <form>
        <div class="person page">
          <div class="title">个人信息</div>
          <div class="info">
            <div>
              姓名：<span>{{ personInfo.realname || "" }}</span>
            </div>
            <div>
              手机号：<span>{{ personInfo.phone || "" }}</span>
            </div>
          </div>
          <div class="title">导语</div>
          <p>
            本次360°测评采用全程在线无记名的方式进行。每位员工可以对多个候选人进行评价，但是对同一个候选人只能评价一次。请尊重您做出的每一次评价。谢谢您的配合！
          </p>
          <!-- 员工列表 -->
          <div class="title">预备核心</div>
          <div class="person-list">
            <div class="list" style="width: 100%">
              <span
                v-for="(item, i) in manageList.list"
                :key="i"
                :class="manageList.yes_list.includes(item.id) ? 'no' : 'on'"
                @click="
                  jumpQuestionnaire(
                    item,
                    manageList.yes_list.includes(item.id) ? 'n' : 'y'
                  )">
                {{ item.realname }}<em v-show="item.aliasname">（{{item.aliasname}}）</em>--{{ item.deptname }}
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- 验证身份信息 -->
    <mt-popup
      class="personDialog"
      v-model="personVisible"
      position="center"
      style="width: 7rem"
      :closeOnClickModal="false"
    >
      <div class="box">
        <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa' }"
          >请输入真实的身份信息</van-divider
        >
        <div class="input">
          <span>姓名：</span
          ><input
            type="text"
            placeholder="请输入姓名"
            maxlength="10"
            v-model="realname"
          />
        </div>
        <div class="input">
          <span>手机号：</span
          ><input
            type="text"
            placeholder="请输入手机号"
            maxlength="11"
            v-model="phone"
          />
        </div>
        <button class="btn" @click="personSubmit">确定</button>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from 'mint-ui';
// import { MessageBox } from "mint-ui";
import { RadioGroup, Radio, Divider } from "vant";
import { isStaff, isStaffBind, staffReadyList } from "@api/user";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Divider);
export default {
  data() {
    return {
      realname: "",
      phone: "",
      form: {},
      result: {},
      personInfo: {},
      manageList: [],
      loading: false,
      personVisible: false,
    };
  },
  created() {
    this.endEvent();
    // this.initEvent();
  },
  methods: {
    // 活动已结束
    endEvent() {
      this.$dialog.error('活动已结束，两秒后自动返回用户中心')
      setTimeout(()=> {
        this.$router.push('./')
      }, 2000)
    },

    // 员工是否绑定信息
    initEvent() {
      let that = this;
      isStaff()
        .then((res) => {
          if (res.data) {
            that.personInfo = res.data;
          }
          that.getStaffReadyList();
          that.loading = true;
        })
        .catch((err) => {
          let status = err.data.status;
          if (status === 201) {
            that.personVisible = true;
          } else {
            that.$dialog.error(err.msg)
          }
        });
    },

    // 绑定员工列表
    getStaffReadyList() {
      let that = this;
      that.$dialog.loading.open()
      staffReadyList().then((res) => {
        that.manageList = res.data[0];
        that.$dialog.loading.close()
      }).catch(err => {
        that.$dialog.error(err.msg)
        that.$dialog.loading.open()
        setTimeout(() => {
          that.$router.push('/user/staffEvaluation')
        }, 2000)
      })
    },

    // 身份信息确认
    personSubmit() {
      let that = this;
      let params = {
        realname: that.realname,
        phone: that.phone,
      };

      if (!that.realname) {
        Toast({message: '请填写姓名', iconClass: 'jym-cuo'});
        return;
      }
      if (!that.phone || !/^1[3456789]\d{9}$/.test(that.phone)) {
        Toast({message: '请填写姓名', iconClass: 'jym-cuo'});
        return;
      }
      isStaffBind(params)
        .then((res) => {
          Toast({message:res.msg, iconClass: 'jym-dui'});
          that.getStaffReadyList();
          that.personInfo = res.data;
          that.personVisible = false;
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        });
    },

    // 优秀员工核心高管跳转
    jumpQuestionnaire(params, boo) {
      let that = this;
      if (boo === "n") {
        that.$dialog.error("当前选择人员已评价！");
        return;
      }
      that.$router.push({
        path: `/user/BackStaffQuestionnaire`,
        query: params,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.staff {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .container {
    font-size: 0.26rem;
    padding: 0 0.2rem 0.3rem 0.2rem;
    .page {
      background: white;
      border-radius: 8px;
      padding: 0.1rem 0.2rem 0.8rem 0.2rem;
      margin-top: 0.3rem;
    }
    .person {
      font-size: 0.28rem;
      .title {
        font-size: 0.3rem;
        margin: 0.4rem 0 0.1rem;
        position: relative;
        padding-left: 0.2rem;
        span {
          font-size: 0.26rem;
          float: right;
          color: #46b8da;
        }
      }
      .title::before {
        content: "";
        position: absolute;
        width: 0.06rem;
        height: 0.3rem;
        top: 0.06rem;
        left: 0;
        background: #46b8da;
      }
      p {
        line-height: 0.42rem;
        color: #333;
        margin-bottom: 0.2rem;
        text-indent: 2em;
      }
      .rule {
        text-indent: 0;
        font-size: 0.26rem;
        line-height: 0.42rem;
        margin-bottom: 0.2rem;
      }
      .info {
        display: flex;
        justify-content: space-between;
        span {
          color: #007bff;
        }
      }
      .person-list {
        display: flex;
        justify-content: start;
        font-size: 0.26rem;
        margin-top: .2rem;
        .list {
          span {
            border-radius: 0.04rem;
            display: inline-block;
            padding: 0.06rem 0.24rem;
            margin: 0 0.16rem 0.16rem 0;
          }
          span.on {
            background: #6487a7;
            color: #f5f5f5;
          }
          span.no {
            background: #aaa;
            color: #666;
          }
        }
      }
      .must {
        color: #5cb85c;
      }
      .nomust {
        color: #46b8da;
      }
    }
  }
  .personDialog {
    .box {
      padding: 0.3rem 0.3rem 0.5rem 0.5rem;
      .input {
        display: flex;
        justify-content: space-between;
        margin-top: 0.2rem;
        font-size: 0.28rem;
        line-height: 0.65rem;
        span {
          color: #666;
          width: 1.5rem;
        }
        input {
          font-size: 0.26rem;
          width: 4.5rem;
          border: 1px solid #ddd;
          border-radius: 0.08rem;
          height: 0.65rem;
          padding-left: 0.2rem;
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.3rem;
        button {
          height: 0.72rem;
          width: 2.4rem;
          text-align: center;
          border-radius: 0.08rem;
          color: white;
          background: #26a2ff;
        }
      }
    }
  }
}
</style>